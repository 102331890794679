export const convertTime = (inputTime) => {
  // Ensure input time is a string
  inputTime = inputTime?.toString();

  // Check if the input time has 4 characters (HHMM format)
  // if (inputTime.length !== 4) {
  //   throw new Error("Invalid time format. Please provide time in HHMM format.");
  // }

  // Extract hour and minute components
  const hour = inputTime?.substring(0, 2);
  const minute = inputTime?.substring(2, 4);

  // Concatenate hour and minute with a colon separator
  const formattedTime = `${hour}:${minute}`;

  return formattedTime;
};
