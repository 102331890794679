import { createAction, createReducer } from "@reduxjs/toolkit";
const Selected_Year = createAction("Selected_Year");

// reducers.js
const initialState = {
  selectedyear: "",
};

export const buttonClickReducer = createReducer(initialState, (builder) => {
  builder.addCase(Selected_Year, (state, action) => {
    state.selectedyear = action.payload;
  });
});
