export const formatCurrency = (inputValue) => {
    // Convert the input value to a number to remove leading zeros
    const numberValue = Number(inputValue);

    // Check if the number is valid
    // if (isNaN(numberValue)) {
    //     throw new Error("Invalid number format.");
    // }

    // Convert the number back to a string
    const formattedValue = numberValue?.toFixed(5);

    return formattedValue;
}
