import React, { useState } from "react";
import ButtonPagenate from "./ButtonPagenate/ButtonPagenate";
import { RiUpload2Fill, RiDownload2Fill } from "react-icons/ri";
import { formatDate } from "./Datefilter/datefilter";
import { formatDateYYYY } from "./Datefilter/datefilteryyyymmdd";
import { formatCurrency } from "./Formatter/formatcurrency";
import { convertTime } from "./Formatter/timeconverter";

const FileUploadY2024 = () => {
  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchdata, setSearchdata] = useState("");

  var newdataset = tableData.filter((row) => {
    if (searchdata === "") {
      return row;
    } else {
      // Convert all values to lowercase for case-insensitive search
      const values = Object.values(row).map((value) => {
        // Check if the value is a string before converting to lowercase
        return typeof value === "string" ? value.toLowerCase() : value;
      });
      // Check if any of the values (converted to lowercase) contains the search string
      return values.some((value) => {
        if (typeof value === "string") {
          return value.includes(searchdata.toLowerCase());
        }
        return false; // Ignore non-string values
      });
    }
  });

  const itemsPerpage = 50; //Number of items to be displayed per page
  const pageCount = Math.ceil(newdataset?.length / itemsPerpage);
  const pagesVisited = pageNumber * itemsPerpage;

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target.result;
      const array = text.split("\n");

      console.log(array.length);
      array.map((rows) => console.log(rows.length));

      // Check if the file contains the expected number of columns
      const expectedColumnCount = 20; // Adjust this value according to your data
      const hasValidFormat = array.every(
        (line) => line.split("\t").length <= expectedColumnCount
      );

      // array.every((line) => console.log(line.split("\t").length));

      if (!hasValidFormat) {
        // Handle the case where the file format is not as expected
        alert("The imported file does not have the expected format.");
        return;
      }
      // setFileContent(array);
      const parsedData = array.map((line, index) => ({
        SN: index + 1,
        "Pseudo City Code": line.substring(0, 5).trim(),
        "Airline Code": line.substring(5, 8).trim(),
        "Board Point": line.substring(8, 11).trim(),
        "Off Point": line.substring(11, 14).trim(),
        // "Passive Segment Indicator": line.substring(14, 15).trim(),
        "PNR Locator": line.substring(15, 21).trim(),
        "Flight Number": line.substring(21, 25).trim(),
        "Current Status Code": line.substring(25, 27).trim(),
        "Previous Status Code": line.substring(27, 29).trim(),
        "Passenger Name": line.substring(29, 49).trim(),
        "Number in Party": line.substring(49, 52).trim(),
        "Booking Date": line.substring(52, 60).trim(),
        "Flight Date": line.substring(60, 68).trim(),
        "Cancel Indicator": line.substring(68, 69).trim(),
        "Access Type": line.substring(69, 71).trim(),
        "Country Code": line.substring(71, 73).trim(),
        "Location Name": line.substring(73, 103).trim(),
        "City and State": line.substring(103, 123).trim(),
        // Filler1: line.substring(123, 131).trim(),
        "ARC/IATA Number": line.substring(131, 139).trim(),
        "Class of Service": line.substring(139, 141).trim(),
        "Agent Duty": line.substring(141, 142).trim(),
        "Agent Sine": line.substring(142, 145).trim(),
        "Create PNR City": line.substring(145, 150).trim(),
        "PNR Type": line.substring(150, 151).trim(),
        "SIDT Channel Indicator": line.substring(151, 152).trim(),
        // "ERSP Number": line.substring(152, 160).trim(),
        "Local Departure Time": line.substring(160, 164).trim(),
        "Connect Level Tag": line.substring(164, 166).trim(),
        "Forced Segment Indicator": line.substring(166, 167).trim(),
        "Original Seg Create Date": line.substring(167, 175).trim(),
        "Alternate Billing Channel": line.substring(175, 177).trim(),
        "Unit Price": line.substring(177, 189).trim(),
        "Currency Code": line.substring(189, 192).trim(),
        // Filler2: line.substring(192, 193).trim(),
        "Marketing Carrier": line.substring(193, 196).trim(),
        // Filler3: line.substring(196, 209).trim(),
        "Operating Carrier Code": line.substring(209, 212).trim(),
        // Filler4: line.substring(212, 280).trim(),
        "Bill-To Code": line.substring(279, 282).trim(),
      }));

      setTableData(parsedData);
    };

    reader.readAsText(file);
  };

  const exportToCSV = () => {
    // Extracting headings from the first row of tableData
    const headings = Object.keys(tableData[0]).join(",");

    // Concatenating headings with the row data
    const csvContent =
      "data:text/csv;charset=utf-8," +
      headings +
      "\n" + // Add headings as the first row
      tableData.map((row) => Object.values(row).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");

    // Get the name of the uploaded file
    const fileNameWithExtension =
      document.querySelector('input[type="file"]').files[0].name;

    // Remove file extension from the file name
    const fileName = fileNameWithExtension.split(".").slice(0, -1).join(".");

    // Set the file name for download
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName + ".csv"); // Append ".csv" to the file name
    document.body.appendChild(link);
    link.click();
  };

  const handleChange = (e) => {
    setSearchdata(e.target.value);
  };

  return (
    <div className="flex flex-col gap-2 h-full">
      <div className="w-full flex items-center justify-between h-[50px] sticky">
        <div className="flex items-center gap-5 w-[20%] sticky left-0">
          <label className="bg-blue-200 p-2 text-gray-600 cursor-pointer rounded-sm border border-blue-200 rounded-md relative items-center justify-between flex gap-2">
            <input
              type="file"
              className="hidden"
              onChange={handleFileUpload}
              accept=".txt"
            />

            <span className="w-[80px] text-[14px] font-[600]">Import File</span>

            <RiDownload2Fill size={20} />
          </label>

          <input
            onChange={handleChange}
            value={searchdata}
            type="text"
            className="border p-2 min-w-[200px] w-[100%] outline-none rounded-md"
            placeholder="Search any text ..."
          />
        </div>

        <div
          className="bg-blue-200 p-2 text-gray-600 cursor-pointer rounded-sm border border-blue-200 rounded-md relative items-center justify-between flex gap-2 text-[14px] font-[600] sticky right-0"
          onClick={exportToCSV}
        >
          <button>Export to CSV</button>

          <RiUpload2Fill size={20} />
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              SN
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Pseudo City Code
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Airline Code
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Board Point
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Off Point
            </th>
            {/* <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Passive Segment Indicator
            </th> */}
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              PNR Locator
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Flight Number
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Current Status Code
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Previous Status Code
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Passenger Name
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Number in Party
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Booking Date
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Flight Date
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Cancel Indicator
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Access Type
            </th>

            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Country Code
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Location Name
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              City and State
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              ARC/IATA Number
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Class of Service
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Agent Duty
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Agent Sine
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Create PNR City
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              PNR Type
            </th>

            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              SIDT Channel Indicator
            </th>
            {/* <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              ERSP Number
            </th> */}
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Local Departure Time
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Connect Level Tag
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Forced Segment Indicator
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Original Seg Create Date
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Alternate Billing Channel
            </th>

            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Unit Price
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Currency Code
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Marketing Carrier
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Operating Carrier Code
            </th>
            <th className="text-[white] min-w-[120px] p-1 h-[70px] bg-[#010e31] text-[14px] font-[600] border">
              Bill-To Code
            </th>
          </tr>
        </thead>

        <tbody>
          {newdataset
            .slice(pagesVisited, pagesVisited + itemsPerpage)
            .map((row, rowIndex) => (
              <tr key={rowIndex} className="text-[13px] font-[500] border">
                {Object.values(row).map((cellValue, cellIndex) => (
                  <td
                    className="border p-1 h-[70px] text-center"
                    key={cellIndex}
                  >
                    {cellIndex === 28
                      ? formatDate(cellValue)
                      : cellIndex === 11 || cellIndex === 12
                      ? formatDateYYYY(cellValue)
                      : cellIndex === 30
                      ? formatCurrency(cellValue)
                      : cellIndex === 25
                      ? convertTime(cellValue)
                      : cellValue}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>

      <div className="flex w-full items-center justify-end">
        <ButtonPagenate
          updatedPageNum={newPagenum}
          pageCount={pageCount}
          pageNumber={pageNumber}
          previousLabel="Prev"
          nextLabel="Next"
        />
      </div>
    </div>
  );
};

export default FileUploadY2024;
