import React, { useState, useEffect } from "react";
import { captureSelectedYear } from "../Redux/Actions/buttonClick";
import { Store } from "../Redux/store";

const YearSelector = () => {
  const [active, setActive] = useState(true);

  useEffect(() => {
    Store.dispatch(captureSelectedYear("2024"));
  }, []);

  const handleYearChange = (e) => {
    let { id } = e.target;

    if (id === "2024") {
      setActive(true);
      Store.dispatch(captureSelectedYear(id));
    } else {
      setActive(false);
      Store.dispatch(captureSelectedYear(id));
    }
  };

  return (
    <div className="flex items-center sticky right-0">
      <div
        className={`p-2 text-[13px] border cursor-pointer ${
          active ? "bg-[#010e31] text-[white] font-[500]" : "text-[#010e31]"
        }`}
        onClick={handleYearChange}
        id="2024"
      >
        Standard Data Format
      </div>

      <div
        className={`p-2 text-[13px] border cursor-pointer ${
          !active ? "bg-[#010e31] text-[white] font-[500]" : "text-[#010e31]"
        }`}
        onClick={handleYearChange}
        id="2023"
      >
        Altered Data Format
      </div>
    </div>
  );
};

export default YearSelector;
