export const formatDateYYYY = (inputDate) => {
    // Check if inputDate is in the correct format
    const dateRegex = /^(\d{4})(\d{2})(\d{2})$/;
    // if (!dateRegex.test(inputDate)) {
    //     throw new Error("Invalid date format. Expected YYYYMMDD.");
    // }

    // Extract year, month, and day from inputDate
    const year = inputDate?.substring(0, 4);
    const month = inputDate?.substring(4, 6);
    const day = inputDate?.substring(6);

    // Format the date as YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}
