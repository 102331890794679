export const formatDate = (inputDate) => {
    // Check if inputDate is in the correct format
    const dateRegex = /^(\d{2})(\d{2})(\d{4})$/;
    // if (!dateRegex.test(inputDate)) {
    //     throw new Error("Invalid date format. Expected MMDDYYYY.");
    // }

    // Extract month, day, and year from inputDate
    const month = inputDate?.substring(0, 2);
    const day = inputDate?.substring(2, 4);
    const year = inputDate?.substring(4);

    // Format the date as MM-DD-YYYY
    const formattedDate = `${month}-${day}-${year}`;

    return formattedDate;
}
