import React, { useState } from "react";
import "./index.css";
import { useSelector } from "react-redux";
import FileUploadY2024 from "./components/UploadfileYR2024";
import FileUploadY2023 from "./components/UploadfileYR2023";
import YearSelector from "./components/Yearselector";

function App() {
  const { selectedyear } = useSelector((state) => state.buttonclick);

  // const [toggledate, setToggledate] = useState("2024");

  return (
    <div className="flex flex-col gap-1 p-2">
      <YearSelector />
      <div className="overflow-x-flex flex">
        {selectedyear === "2024" ? <FileUploadY2024 /> : <FileUploadY2023 />}
      </div>
    </div>
  );
}

export default App;
